* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #eeeeee;
    height: 100%;
}

.navLinkActive {
    color: #FFFFFF !important;
    font-weight: 800;
    margin-left: 30px;
}

.navLink {
    text-decoration: none;
    color: #FFFFFF !important;
    margin-left: 30px;
    display: inline-flex;
    align-items: center;
}

.authContainer {
    color: black;
    width: 350px;
    margin: 20% auto 0;
}

.modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    outline: none;
    width: 300px;
    height: auto;
    padding: 30px;
    box-shadow: 0 4px 10px black;
    background-color: white;
    border-radius: 12px;
    box-sizing: border-box;
}

.greenLabel {
    color: #6BBB72;
}

.redLabel {
    color: #FF6466;
}


.modalHeader {
    margin-bottom: 30px;
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid #FCAD2A;
}

.modalBody {

}

.depositField {
    margin-top: 30px;
    margin-bottom: 50px;
    padding: 15px;
    background: #F8F9FB;
    border-radius: 8px;
}

.depositFieldCaption {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #D8D8D8;
}

.depositFieldBody {
    font-size: 14px;
    color: #8A8A8E;
}

.actionIcon {
    cursor: pointer;
}

.fieldLabel {
    margin-top: 15px;
    margin-bottom: 3px;
    color: #8A8A8E;
}

.red {
    color: #FF6466;
}

.table > tr > td {
    padding: 3px;
}

.table > thead > tr > th {
    text-align: left;
    color: #8A8A8E;
    font-weight: 500;
    padding: 3px 3px 20px;
}
.table > thead {

}

.center {
    text-align: center;
}

.cancelLink {
    color: #8E8E93;
    text-align: center;
    margin-top: 15px;
    cursor: pointer;
}

.buttonContainer {
    text-align: right;
    margin-top: 30px;
}

.buttonContainer>button {
    margin-right: 20px;
}


.pageContent {
    height: 100%;
    margin: 15px auto 0 210px;
}

.logo {
    width: 50px;
    height: 50px;
    background-image: url("./images/logo.png");
    background-size: cover;
    margin-right: 30px;
}

.logoV1 {
    height: 60px;
    background-image: url("./images/logo_v1.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 30px 0 auto;
}

.logoV1Small {
    height: 25px;
    width: 125px;
    margin: 0 auto;
    background-image: url("./images/logo_v1.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.logoV1Big {
    width: 300px;
    height: 60px;
    margin: 0 auto;
}

.logoV2 {
    width: 200px;
    height: 183px;
    background-image: url("./images/logo_v2.png");
    background-size: contain;
    margin: 0 auto;
}

.walletContainer {
    display: inline-flex;
    font-size: 14px;
    align-items: center;
}

.walletContainer > div {
    display: flex;
    flex-grow: 1;
}

.ppcWalletContainer {
    display: block;
    font-size: 14px;
    align-items: center;
}


.content {
    width: 1000px;
    margin: 15px auto 0 230px;
}

.box {
    margin: 10px;
    padding: 15px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 20px 50px rgba(191, 21, 108, 0.05);
    border-radius: 12px;
}

.boxRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    overflow: scroll;
}

.flexResponsive {
    display: flex;
}

.centerVertical {
    align-items: center;
}

.withBorders {
    border-left: 1px solid #EDEDED;
    border-right: 1px solid #EDEDED;
}

.boxPrice {
    padding: 10px;
    flex: 1;
}

.greyLabel {
    color: #969B9F;
    font-size: 14px;
    margin-bottom: 3px;
}

.fontWeight600 {
    font-weight: 600;
}

.ppcIcon {
    width: 22px;
    height: 22px;
    background: #FA9D2A;
    border-radius: 6px;
    margin-right: 5px;
}

.intIcon {
    width: 22px;
    height: 22px;
    background: #47CFF5;
    border-radius: 6px;
    margin-right: 5px;
}

.portfolioIcon {
    width: 24px;
    height: 24px;
    background: #0D80DC;
    color: white;
    padding: 3px;
    border-radius: 8px;
    margin-right: 10px;
}

.whiteIcon {
    width: 48px;
    height: 48px;
    background: white;
    border-radius: 8px;
    margin-right: 10px;
}

.cryptoIcon {
    width: 32px;
    height: 32px;
    background-size: contain;
    background-repeat: no-repeat;
}
.btcIcon {
    background-image: url("./images/icon_btc.png");
}

.usdtIcon {
    background-image: url("./images/icon_usdt.png");
}

.ethIcon {
    background-image: url("./images/icon_eth.png");
}

table {
    width: 100%;
}
table > tbody > tr > td {
    padding: 3px;
}

.colorGreen {
    color: #1DAB5F
}

.backgroundGreen {
    color: #1DAB5F
}

.colorRed {
    color: #FF6466
}

.colorGray {
    color: #969B9F
}

.backgroundRed {
    color: #FF6466
}

.th {
    color: #969B9F !important;
    font-weight: 500;
}

.avatar {
    background: #0D80DC;
    width: 128px;
    height: 128px;
    border-radius: 82px;
    margin-right: 30px;
}

.mobileHeaderContainer{
    margin-bottom: 35px;
}

.mobileHeader {
    width: 100%;
    height: 70px;
    background: linear-gradient(90deg, #F5DA30 0%, #F3A128 100%);
}

.mobileHeaderCircle {
    position: absolute;
    height: 70px;
    width: 70px;
    top: 35px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    background: #FFDC87;
}

.mobileCaption {
    font-size: 22px;
    font-weight: 600;
}

.mobileContent {
    padding: 10px;
}

.mobileMargin {
    margin-top: 30px;
}

.MuiTableCell-sizeSmall {
    padding: 3px 1px 1px 0 !important;
}

@media screen and (max-width: 1024px){
    .pageContent {
        margin: 0 !important;
        background: white;
    }
}

@media screen and (max-width: 768px) {

    .authContainer {
        max-width: 90% !important;
    }
    .modal {
        width: 90% !important;
        height: auto !important;
    }
    .walletContainer {
        margin-left: 0 !important;
        font-size: 13px;
    }
    .logoV1 {
        width: 300px;
        height: 60px;
    }
    .content {
        width: 99% !important;
    }
    .logoV1Big {
        margin-top: 10%;
    }
    .pageContent {
        margin: 0 !important;
        background: white;
        height: auto;
    }
    body {
        background: white;
    }
    .boxRow {
        display: block;
    }
    .box {
        box-shadow: none !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 0 !important;
    }
    .MuiTableCell-sizeSmall {
        padding: 3px 1px 1px 0 !important;
    }
}
