.accountPanelBox {
    width: 50%;
    margin-top: 0;
}

.accountPanelBox1 {
    width: 60%;
    margin-top: 30px
}

.accountFunds {
    margin-top: 30px;
}

@media screen and (max-width: 768px){
    .accountPanelBox {
        margin-top: 30px;
        width: 100%;
    }
    .accountPanelBox1 {
        width: 100%;
    }
}
