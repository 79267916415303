.ordersBox {
    width: 50%;
}

.depositsDescription {
    cursor: pointer;
    margin-left: 10px;
}

.depositsCryptoName {
    font-weight: 600;
}

.depositsCryptoDescription {
    color: #8A8A8E;
    font-size: 14px;
    margin-top: 3px;
}

.mobileTradeAmount {
    width: 50%;
    height: auto;
    background: linear-gradient(90deg, #F3A128 0%, #F5DA30 100%);
    box-shadow: 5px 0px 15px rgba(112, 109, 109, 0.25);
    border-radius: 8px;
    display: flex;
    color: white;
    padding: 10px;
    box-sizing: border-box;
}

.mobileTradePrice {
    width: 50%;
    height: auto;
    background: #F1B442;
    border-radius: 8px;
    display: flex;
    color: white;
    padding: 10px;
    box-sizing: border-box;
}

.mobileDepositFunds {
    width: 48px;
    height: 48px;
    background: #F2F2F7;
    border-radius: 8px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.mobileDepositFundsContainer {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
}