.mobileCurrencyIcon {
    width: 40px;
    height: 40px;
    background: #E5E5EA;
    border-radius: 50%;
    margin-right: 10px;
}

.mobilePriceLine {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.mobileTokenInfo {
    width: 100%;
    height: 144px;
    border-radius: 8px;
    margin-top: 30px;
    padding: 10px;
    color: white;
    box-sizing: border-box;
    background: linear-gradient(90deg, #F3A128 0%,  #F5DA30 100%);
}

.mobileTokenHigh {
    width: 88px;
    height: 54px;
    background: #6BBB72;
    border-radius: 4px;
    color: white;
    margin-right: 5px;
    text-align: center;
}

.mobileTokenLow {
    width: 88px;
    height: 54px;
    background: #FF6466;
    border-radius: 4px;
    color: white;
    text-align: center;
}

.mobileAboutToken {
    background: #F8F9FB;
    border-radius: 8px;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 20px;
}

.iconBuySell {
    width: 56px;
    height: 56px;
    background: #FFF8E8;
    color: #FCAD2A;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}

.pricesModalButtonContainer {
    display: flex;
    width: 180px;
    justify-content: space-between;
    margin: 0 auto;
}