.guestBody {
    background-color: white;
    background-image: url("/src/images/login_bg.jpeg");
    height: 100%;
    background-size: cover;
}

.guestContent {
    max-width: 800px;
    padding-top: 3%;
    margin: 0 auto;
    color: #FFFFFF;
}

.guestTitle {
    text-align: center;
    font-weight: 700;
}

.forgotPasswordLink {
    position: absolute;
    top: 138px;
    right: 0;
    font-size: 14px;
    color: #FCAD2A;
    cursor: pointer;
}