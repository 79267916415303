.portfolioMobileBox1 {
    margin-top: 30px;
    padding: 10px;
    color: white;
    box-sizing: border-box;
    width: 100%;
    height: 96px;
    background: linear-gradient(90deg, #F3A128 0%,  #F5DA30 100%);
    border-radius: 8px;
}

.portfolioMobileBox2 {
    background-image: url("../../images/portfolio_box2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 230px;
    border-radius: 8px;
    margin-top: 30px;
}

.portfolioMobileTokensContainer {
    display: flex;
    overflow-x: scroll;
    margin-top: 10px;
    height: 230px;
    margin-bottom: 30px;
}

.portfolioMobileTokenBox {
    cursor: pointer;
    min-width: 152px;
    height: 208px;
    background: #F8F9FB;
    border-radius: 8px;
    margin-right: 5px;
    text-align: center;
    padding: 15px;
    box-sizing: border-box;
}

.ppcIconMobile {
    width: 72px;
    height: 72px;
    background: #FCAD2A;
    border-radius: 50%;
    margin: 0 auto 15px;
}

.govIconMobile {
    width: 72px;
    height: 72px;
    background: #FFDC87;
    border-radius: 50%;
    margin: 0 auto 15px;
}

.vctIconMobile {
    width: 72px;
    height: 72px;
    background: #E5E5EA;
    border-radius: 50%;
    margin: 0 auto 15px;
}


