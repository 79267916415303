.portfolioPanelBox {
    width: 50%;
}

.portfolioValueCation {
    font-weight: 600;
    font-size: 22px;
    display: flex;
    margin-bottom: 20px;
}

.portfolioValue {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 7px;
}

.portfolioCryptoBox {
    width: 100%;
    height: 80px;
    color: white;
    box-shadow: 0 20px 50px rgba(191, 21, 108, 0.05);
    border-radius: 12px;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 15px;
}

.portfolioPPC {
    background: #FA9D2A;
    margin-right: 10px;
}

.portfolioGOV {
    background: #4892FE;
    margin-left: 10px;
}

.portfolioVCT {
    background: #47CFF5;
    margin-right: 10px;
}

.portfolioTBA {
    background: #4BD884;
    margin-left: 10px;
}